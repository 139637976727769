<template>
  <div class="mt-4" aria-label="intro">
    <div aria-label="slate html">
      <p style="text-align:center;">
        <strong><span style="font-size:20px;">防疫實聯制登記表</span></strong>
      </p>
      <p style="text-align:center;"><strong>Contact-Based Policy</strong></p>
      <br />
      <p style="text-align:center;">
        <span style="color:rgb(218, 30, 92);">
          <strong>
            <span style="font-size:20px;">
              <span style="background-color:rgb(255, 255, 255);">
                發生錯誤！請聯絡相關人員。
              </span>
            </span>
          </strong>
        </span>
      </p>
    </div>
  </div>
</template>
