<template>
  <div class="mt-4" aria-label="intro">
    <div aria-label="slate html">
      <p style="text-align:center;">
        <strong><span style="font-size:20px;">防疫實聯制登記表</span></strong>
      </p>
      <p style="text-align:center;"><strong>Contact-Based Policy</strong></p>
      <br />
      <p style="text-align:center;">
        <span style="color:rgb(218, 30, 92);">
          <strong>
            <span style="font-size:20px;">
              <span style="background-color:rgb(255, 255, 255);">
                上傳成功！
              </span>
            </span>
          </strong>
        </span>
      </p>
      <p style="text-align:center;">
        <span style="color:#da1e5c;">Completed!</span>
      </p>
      <p style="text-align:center;">
        <span style="font-size:1.5rem; color:#da1e5c;">
          {{ displayDate() }}
        </span>
      </p>
      <br />
      <p style="text-align:center;">
        <span style="font-size:20px;">請您向服務人員出示此畫面</span>
      </p>
      <p style="text-align:center;">Please show this screen to our crew.</p>
      <p style="text-align:center;">
        <span style="font-size:20px;">感謝您的配合!</span>
      </p>
      <p style="text-align:center;">Thank you for your cooperation.</p>
      <p></p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Result',
  methods: {
    displayDate() {
      const date = new Date()
      const Month = String(date.getMonth()+1).padStart(2, '0')
      const Hour = String(date.getHours()).padStart(2, '0')
      const Minute = String(date.getMinutes()).padStart(2, '0')

      return `${date.getFullYear()}/${Month}/${date.getDate()} ${Hour}:${Minute}`
    }
  }
}
</script>