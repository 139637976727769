<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | 防疫實聯制` : `防疫實聯制`
    }}</template>
  </metainfo>
  <div v-if="company && company.cover && type !== 'session'">
    <img class="mx-auto" :src="`${FILE_URL}/${company.cover}`" />
  </div>
  <hr />
  <ErrorPage v-if="status === -1" />
  <Main
    v-if="company !== null && status === 0 && type === 'init'"
    :company="company"
    @submited="onSubmitedHandler"
  />
  <OAuthLogin v-if="company !== null && status === 0 && type === 'session'" />
  <OAuthSignup
    v-if="
      company !== null &&
        oauthProfile !== null &&
        status === 0 &&
        type === 'uid'
    "
    :company="company"
    :oauthProfile="oauthProfile"
    :provider="provider"
    :state="state"
    :uid="uid"
  />
  <Result v-if="status === 1" />
</template>

<script>
import { useMeta } from "vue-meta";
import axios from "axios";

import Main from "./components/Main.vue";
import Result from "./components/Result.vue";
import ErrorPage from "./components/ErrorPage.vue";
import OAuthLogin from "./components/OAuthLogin.vue";
import OAuthSignup from "./components/OAuthSignup.vue";

export default {
  name: "App",
  setup() {
    useMeta({
      title: "",
      // htmlAttrs: { lang: 'en', amp: true }
    });
  },
  data() {
    return {
      status: 0,
      type: null,
      company: null,
      provider: null,
      uid: null,
      oauthProfile: null,
      FILE_URL: process.env.VUE_APP_FILE_URL,
      state: null,
    };
  },
  components: {
    Main,
    Result,
    ErrorPage,
    OAuthLogin,
    OAuthSignup,
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let companyId = null;
    const type = urlParams.get("type");
    this.type = type;
    if (this.type === "init") {
      companyId = urlParams.get("companyId");
      await this.loadData(companyId);
      const token = localStorage.getItem("token");
      const memberId = localStorage.getItem("memberId");
      const expire = localStorage.getItem("expire");
      const refresh = localStorage.getItem("refresh");

      if (token && memberId && expire && refresh) {
        const info = { token, memberId, expire, refresh };
        const params = new URLSearchParams();
        params.append("type", "session");
        params.append("returnCode", "000000");
        params.append("returnMessage", "Success");
        params.append("info", JSON.stringify(info));
        params.append("state", companyId);
        location.search = params.toString();
      } else {
        localStorage.clear();
      }
    } else if (this.type === "uid") {
      const state = urlParams.get("state");
      companyId = state.split(",")[0];
      await this.loadData(companyId);
      this.state = state;
      const info = JSON.parse(urlParams.get("info"));
      console.log(info);
      this.provider = info.provider;
      this.uid = info.uid;
      this.getProfileByUid(info.uid);
    } else if (this.type === "session") {
      const state = urlParams.get("state");
      companyId = state.split(",")[0];
      await this.loadData(companyId);
      this.state = state;
      const info = JSON.parse(urlParams.get("info"));
      const { token, memberId, expire, refresh } = info;
      localStorage.setItem("token", token);
      localStorage.setItem("memberId", memberId);
      localStorage.setItem("expire", expire);
      localStorage.setItem("refresh", refresh);
      if (new Date() > new Date(expire)) {
        // {{gw}}/public/core/v1/sessions/55862493ccd876c9d31c075a51b58528?type=refresh
        await axios
          .get(`/gateway/public/core/v1/sessions/${refresh}?type=refresh`)
          .then(({ data }) => {
            if (data.returnCode === "000000") {
              localStorage.clear();
              const { token, memberId, expire, refresh } = data.info;
              localStorage.setItem("token", token);
              localStorage.setItem("memberId", memberId);
              localStorage.setItem("expire", expire);
              localStorage.setItem("refresh", refresh);
              return this.visitProcess(memberId, token);
            } else {
              this.status = -1;
            }
          });
      } else {
        await this.visitProcess(memberId, token);
      }
    }

    if (!companyId || !type) this.status = -1;

    // console.log(idCompanies);
  },
  methods: {
    visitProcess(memberId, token) {
      return axios
        .get(`/gateway/private/core/v1/profiles/${memberId}`, {
          headers: { "X-Access-Token": token },
        })
        .then(({ data }) => {
          if (data.returnCode === "000000") {
            const profile = data.info;
            const postData = {
              idCompanies: this.company.idCompanies,
              memberId,
              name: profile.nickname,
              phone: profile.phone,
            };
            return axios.post("/gateway/private/sales/v1/visits", postData, {
              headers: { "X-Access-Token": token },
            });
          } else {
            this.status = -1;
          }
        })
        .then(({ data }) => {
          if (data.returnCode === "000000") this.status = 1;
        });
    },
    getProfileByUid(uid) {
      axios({
        method: "GET",
        url: `/gateway/public/core/v1/oauth/profile/${uid}`,
      }).then(({ data }) => {
        if (data.returnCode === "000000") {
          this.$data.oauthProfile = data.info;
        }
      });
    },
    loadData(companyId) {
      return axios({
        method: "GET",
        url: `/gateway/public/sales/v1/companies/uuid/${companyId}`,
      }).then(({ data }) => {
        if (data.returnCode === "000000") {
          this.$data.company = data.info;
        }
      });
    },
    onSubmitedHandler(value) {
      this.status = value;
    },
  },
};
</script>

<style></style>
