<template>
  <div aria-label="container" class="container mx-auto px-4">
    <div style="min-height: 641px">
      <div>
        <div aria-label="survey content">
          <header aria-label="header">
            <div aria-label="intro">
              <h1
                style="font-size: 26px; font-weight: 700; text-align: center;"
              >
                COVID-19 防疫實聯制 Contact-Based Policy
              </h1>
              <div aria-label="slate html">
                <br />
                <p style="text-align: center">
                  <span style="font-size: 18px">親愛的顧客您好</span>
                </p>
                <p style="text-align: center">Dear Customers</p>
                <p style="text-align: center">
                  <span style="font-size: 18px"
                    >本資料僅於疫調追蹤不會作為其他用途，</span
                  >
                </p>
                <p style="text-align: center">
                  Your personal information is collected for contact-based
                  policy only.
                </p>
                <p style="text-align: center">
                  <span style="font-size: 18px"
                    >最多保存28天後將刪除銷毀。</span
                  >
                </p>
                <p style="text-align: center">
                  We will voluntarily erase your personal information after 28
                  days.
                </p>
                <p style="text-align: center">
                  <span style="font-size: 18px">謝謝您的合作！</span>
                </p>
                <p style="text-align: center">
                  Thank you for your cooperation.
                </p>
                <br /><br />
              </div>
            </div>
          </header>
          <div class="w-full">
            <form class="bg-white rounded" @submit.prevent="onSubmitHandler">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="username"
                >
                  使用者帳號 Username
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  required
                  v-model.lazy="username"
                />
                <div
                  v-if="!isUsernameValid"
                  class="text-red-700 text-sm mb-1 py-1"
                >
                  此名稱已被使用，請更改使用者名稱。
                </div>
                <div
                  v-if="isUsernameValid"
                  class="text-green-700 text-sm mb-1 py-1"
                >
                  此名稱可以使用。
                </div>
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="name"
                >
                  姓名 Name
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  required
                  v-model="name"
                />
              </div>
              <div class="mb-6">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="phone"
                >
                  聯繫電話 Phone number
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  required
                  v-model="phone"
                />
              </div>
              <div class="flex items-center justify-center mb-4">
                <button
                  class="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  送出
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>
                <div style="transform: scaleX(0.33)"></div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Main",
  props: {
    company: Object,
    oauthProfile: Object,
    provider: String,
    uid: String,
    state: String,
  },
  data() {
    return {
      oauthChannel: null,
      name: "",
      phone: "",
      username: "",
      password: "",
      isUsernameValid: true,
    };
  },
  async mounted() {
    this.generatePassword();
    this.username = this.getUsernameFromEmail(this.oauthProfile.email);
    this.name = this.oauthProfile.name;
    // console.log(this.company);
    // const params = new URLSearchParams();
    // params.append("redirectUri", location.protocol + "//" + location.host);
    // params.append("state", this.company.companyId);
    // const {
    //   data,
    // } = await axios.get(
    //   `/gateway/public/core/v1/oauth-channel?${params.toString()}`,
    //   { headers: { "X-Access-Project-Code": this.company.projectCode } }
    // );
    // if (data.returnCode === "000000") this.oauthChannel = data.info;
  },
  watch: {
    username(value) {
      this.checkUsernameExist(value);
      console.log(value);
    },
  },
  methods: {
    checkUsernameExist() {
      axios
        .get("/gateway/public/core/v1/members/exist/" + this.username)
        .then(({ data }) => {
          if (data.returnCode === "000000") this.isUsernameValid = true;
          else this.isUsernameValid = false;
        });
    },
    getUsernameFromEmail(email) {
      return email.substr(0, email.indexOf("@"));
    },
    generatePassword() {
      if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.password)) {
        return;
      }
      this.password = this.randomString(10);
      console.log(this.password);
      this.generatePassword();
    },
    randomString(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    onSubmitHandler() {
      if (!this.isUsernameValid) return;

      const postData = {
        username: this.username,
        password: this.password,
        nickname: this.name,
        email: this.oauthProfile.email,
        phone: this.phone,
        projectCode: this.company.projectCode,
      };
      if (this.provider === "LINE") {
        postData.lineId = this.oauthProfile.sub;
      }
      if (this.provider === "GOOGLE") {
        postData.google = this.oauthProfile.sub;
      }
      console.log(postData);

      axios
        .post("/gateway/public/core/v1/members", postData)
        .then(({ data }) => {
          if (data.returnCode === "000000") {
            return axios.post("/gateway/public/core/v1/sessions", {
              username: this.username,
              password: this.password,
            });
          }
        })
        .then(({ data }) => {
          if (data.returnCode === "000000") {
            const params = new URLSearchParams();
            params.append("type", "session");
            params.append("returnCode", data.returnCode);
            params.append("returnMessage", data.returnMessage);
            params.append("info", JSON.stringify(data.info));
            params.append("state", this.state);
            location.search = params.toString();
          }
        });
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}
</style>
