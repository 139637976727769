<template>
  <div aria-label="container" class="container mx-auto px-4">
    <div style="min-height: 641px">
      <div>
        <div aria-label="survey content">
          <header aria-label="header">
            <div aria-label="intro">
              <h1
                style="font-size: 26px; font-weight: 700; text-align: center;"
              >
                COVID-19 防疫實聯制 Contact-Based Policy
              </h1>
              <div aria-label="slate html">
                <br />
                <p style="text-align: center">
                  <span style="font-size: 18px">親愛的顧客您好</span>
                </p>
                <p style="text-align: center">Dear Customers</p>
                <p style="text-align: center">
                  <span style="font-size: 18px"
                    >本資料僅於疫調追蹤不會作為其他用途，</span
                  >
                </p>
                <p style="text-align: center">
                  Your personal information is collected for contact-based
                  policy only.
                </p>
                <p style="text-align: center">
                  <span style="font-size: 18px"
                    >最多保存28天後將刪除銷毀。</span
                  >
                </p>
                <p style="text-align: center">
                  We will voluntarily erase your personal information after 28
                  days.
                </p>
                <p style="text-align: center">
                  <span style="font-size: 18px">謝謝您的合作！</span>
                </p>
                <p style="text-align: center">
                  Thank you for your cooperation.
                </p>
                <br /><br />
              </div>
            </div>
          </header>
          <template v-if="oauthChannel">
            <div
              class="flex items-center justify-center mb-4"
              v-if="oauthChannel.line"
            >
              <a
                :href="oauthChannel.line"
                class="w-full text-white text-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                style="background-color: #00C300;"
                type="submit"
              >
                Log in with LINE
              </a>
            </div>
            <div
              class="flex items-center justify-center mb-4"
              v-if="oauthChannel.google"
            >
              <a
                :href="oauthChannel.google"
                class="w-full text-white text-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                style="background-color: #4285f4;"
                type="submit"
              >
                Log in with GOOGLE
              </a>
            </div>
            <div class="flex mt-7 items-center text-center">
              <hr class="border-gray-300 border-1 w-full rounded-md" />
              <label class="block font-medium text-sm text-gray-600 w-full">
                OR
              </label>
              <hr class="border-gray-300 border-1 w-full rounded-md" />
            </div>
          </template>
          <div class="w-full">
            <form class="bg-white rounded" @submit.prevent="onSubmitHandler">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="name"
                >
                  姓名 Name
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  required
                  v-model="name"
                />
              </div>
              <div class="mb-6">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="phone"
                >
                  聯繫電話 Phone number
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  required
                  v-model="phone"
                />
              </div>
              <div class="flex items-center justify-center mb-4">
                <button
                  class="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  送出
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div>
              <div>
                <div style="transform: scaleX(0.33)"></div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Main",
  props: {
    company: Object,
  },
  data() {
    return {
      oauthChannel: null,
      name: "",
      phone: "",
    };
  },
  async mounted() {
    console.log(this.company);
    const params = new URLSearchParams();
    params.append("redirectUri", location.protocol + "//" + location.host);
    params.append("state", this.company.companyId);
    const {
      data,
    } = await axios.get(
      `/gateway/public/core/v1/oauth-channel?${params.toString()}`,
      { headers: { "X-Access-Project-Code": this.company.projectCode } }
    );
    if (data.returnCode === "000000") this.oauthChannel = data.info;
  },
  methods: {
    onSubmitHandler() {
      const postData = {
        idCompanies: this.company.idCompanies,
        name: this.name,
        phone: this.phone,
      };
      axios
        .post("/gateway/public/sales/v1/visits", postData)
        .then(({ data }) => {
          if (data.returnCode === "000000") this.$emit("submited", 1);
        });
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}
</style>
